<template>
  <v-app id="registro">
    <v-main>
      <v-container
        fluid
        fill-height
        :class="$store.state.ajustes.class_simple_layout_container"
      >
        <v-layout
          align-center
          justify-center
        >
          <div
            xs10
            sm4
            md3
          >
            <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
              <v-form @submit.prevent="passes(save)">
                <h1 class="blue--text text--darken-3">Crear Cuenta</h1>

                <ValidationProvider vid="email" name="Email" rules="required|email" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="email"
                    append-icon=" "
                    type="email"
                    label="Email"
                    required
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider vid="password" name="Clave" rules="required|confirmed:password_confirmation" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="password"
                    type="password"
                    autocomplete="new-password"
                    name="input-10-1"
                    label="Clave"
                    counter
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider vid="password_confirmation" name="Repetir Clave" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="password_confirmation"
                    type="password"
                    name="input-10-1"
                    label="Repetir Clave"
                    counter
                    :error-messages="errors"
                    :success="valid"
                    ></v-text-field>
                </ValidationProvider>

                <ValidationProvider vid="doc_tipo_id" name="Tipo Documento" rules="required" v-slot="{ errors, valid }">
                  <v-select
                    autofocus
                    v-model="doc_tipo_id"
                    :items="tiposDocumento"
                    item-value="id"
                    item-text="descripcion"
                    filled
                    dense
                    label="Tipo Documento"
                    required
                    :error-messages="errors"
                    :success="valid"
                  ></v-select>
                </ValidationProvider>

                <ValidationProvider vid="doc_numero" name="Número de Documento" rules="required" v-slot="{ errors, valid }">
                  <v-text-field
                    v-model="doc_numero"
                    label="Número de Documento"
                    filled
                    dense
                    required
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider vid="apellidos" name="Apellidos" rules="required" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="apellidos"
                    label="Apellidos"
                    required
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider vid="nombres" name="Nombres" rules="required" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="nombres"
                    label="Nombres"
                    required
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider vid="obra_social_id" name="Obra Social" rules="required" v-slot="{ errors, valid }">
                  <v-select
                    v-model="obra_social_id"
                    :items="obrasSociales"
                    item-value="id"
                    item-text="nombre"
                    filled
                    label="Obra Social"
                    required
                    :error-messages="errors"
                    :success="valid"
                  ></v-select>
                </ValidationProvider>

                <div align-self-center class="mt-2">
                  <v-btn type="submit" block @click.prevent="passes(save)" color="primary"
                    dark :loading="loading">Guardar</v-btn>
                  <v-btn type="button" block :to="{name: 'login'}" color="secondary" text :disabled="loading">Cancelar</v-btn>
                </div>
              </v-form>
            </ValidationObserver>
          </div>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    loading: false,
    email: '',
    password: '',
    password_confirmation: '',
    doc_tipo_id: 96,  
    doc_numero: '',
    apellidos: '',
    nombres: '',
    obra_social_id: null,
    tiposDocumento: [],
    obrasSociales: [],
  }),
  methods: {
    save: function () {
      this.loading = true

      const data = {
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
        doc_tipo_id: this.doc_tipo_id,
        doc_numero: this.doc_numero,
        apellidos: this.apellidos,
        nombres: this.nombres,
        obra_social_id: this.obra_social_id,
      }

      this.$http.post('account', data)
      .then((response) => {
        this.$eventHub.$emit('snackbar-message', response.data.message, 'success')

        this.$refs.obs.reset();
        this.$router.push({name: 'login'}, () => {})
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          case 422:
            this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario')

            this.$refs.obs.setErrors(error.response.data.errors);

            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    fetchTiposDocumentos: function () {
      return this.$http.get('tipos-documento')
      .then((response) => {
        this.tiposDocumento = response.data
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
    },

    fetchObrasSociales: function () {
      return this.$http.get('obras-sociales')
      .then((response) => {
        this.obrasSociales = response.data

        if (this.obrasSociales.length > 0) {
          this.obra_social_id = this.obrasSociales[0].id;
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
    },
  },

  created () {
    this.fetchTiposDocumentos()
    this.fetchObrasSociales()
  }
}
</script>

<style>
  #registro .success--text {
      color: #1B5E20 !important;
      caret-color: #1B5E20 !important;
  }

  #registro .error--text {
      color: #D32F2F !important;
      caret-color: #D32F2F !important;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0px solid #212121;
    -webkit-text-fill-color: #212121;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;

  }
</style>
